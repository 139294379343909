import supabase from "@/store/supabase-client"
import { RouteName, RouterBuilder } from "@/router/router-builder"
import { NavigationGuardNext, Route } from "vue-router"
import { isSlackInvestigation } from "./01.auth.guard"
import {usePostLoginStore} from "@/application/store/post-login.store";
const { isSignUp } = usePostLoginStore()

export default async function accountBasedRoutesGuard(to: Route, _from: Route, next: NavigationGuardNext) {
  const requestedAccount = supabase.accounts.find(
    (account) => account.name === to.params.account
  )

  if (!to.matched.some(r => r.meta.accountBased)) {
    return next()
  }

  if (isSlackInvestigation.value && typeof to.query.account === 'string' && !supabase.accounts.find((account) => account.id === to.query.account)) {
    return next(RouterBuilder.getAccountAccessRequestRoute({
      account: to.params.account,
      account_id: to.query.account as string
    }))
  }

  if (to.params.account && !requestedAccount) {
    return next(RouterBuilder.getAccountAccessRequestRoute({
      account: to.params.account,
      account_id: to.query.account as string
    }))
  }

  if (!supabase.accounts.length) {
    if (supabase.invitations.length) {
      return next(RouterBuilder.getInvitationRoute())
    }

    if (isSignUp.value) {
      return next(RouterBuilder.getCreateAccountRoute())
    } else {
      const isSelfHosted = (window as any).ENV_SELF_HOSTED

      if (isSelfHosted) {
        return next(RouterBuilder.getAccountAccessRequestRoute())
      } else {
        return next(RouterBuilder.getNoAccountsFoundRoute())
      }
    }
  }

  if(!to.params.account) {
    const redirectAccount =
      supabase.activeAccount ??
      // Legacy code that allows to set active account using query param
      supabase.accounts.find(
        (account) =>
          [to.query.accountName, to.query.account_name, to.query.account].includes(account.name) ||
          [to.query.accountId, to.query.account_id, to.query.account].includes(account.id),
      ) ??
      // Try to get the last account that was active
      supabase.accounts.find(account => {
        return account.id === supabase.savedActiveUserAccounts[supabase.userId]
      }) ??
      supabase.accounts[0]

    return next({
      name: <string>to.name,
      params: {
        ...to.params,
        account: redirectAccount.name,
      },
      query: to.query,
      hash: to.hash,
    })
  }

  if (!requestedAccount) {
    return next({
      name: RouteName.NotFound,
      query: to.query,
      hash: to.hash,
    })
  }

  await supabase.setActiveAccount(requestedAccount)
  next()
}
